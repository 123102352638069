
































































































































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import { RoadLink } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";

@Component({
    components:{}
})
export default class RoadLinkCreateView extends Vue {
    roadLink: RoadLink = {} as RoadLink;

    async created() {
        const id: any = this.$route.query["id"]

        if(id) {
            await metaStore.getRoadLinkById(id);
            this.roadLink = metaStore.roadLink;
        }
    }

    get AdminRouter() {
        return AdminRouter;
    }

    async onSubmit() {
        const id = this.$route.query["id"]
        let isValid = await this.$validator.validateAll();
        if (!isValid) {
            return;
        }
        if(id) {
            await metaStore.patchRoadLink(this.roadLink);
            this.$snotify.success("RoadLink Changed Successfully");
        }
        else{
            await metaStore.createRoadLink(this.roadLink);
            this.$snotify.success("RoadLink Created Successfully");
        }
        this.$router.push(AdminRouter.RoadLink);
    }

    onCancel() {
        this.$router.push(AdminRouter.RoadLink);
    }
}
